import '@cdt81/scss/app.scss';
import '@cdt81/scss/app-email.scss';
import '@cdt81/scss/app-editor.scss';
import '@cdt81/js/bootstrap.bundle';
import Utils from '@cdt81/js/services/utils';
import Form from '@cdt81/js/services/form';
import Map from '@cdt81/js/services/map';
import Slider from '@cdt81/js/services/slider';
import TourismAffinity from '@cdt81/js/services/tourism-affinity';
import Gallery from '@cdt81/js/services/gallery';
import RoadTrip from '@cdt81/js/services/road-trip';

document.addEventListener( "DOMContentLoaded", function () {
    new Utils();
    new Form();
    new Map();
    new Slider();
    new TourismAffinity();
    new Gallery();
    new RoadTrip();
})
