import 'slick-carousel/slick/slick.min';
import Utils from '@cdt81/js/services/utils';

export default class Slider {

    constructor() {
        Slider.initializeHomeSlider();
        Slider.initializeBlockSlider($('.wp-block-related-post-slider-block'));
        Slider.initializeBlockSliderGuttemberg();

        $(document).on('wpet_tourism_affinity_reload_block', function (e) {
            $('.wp-block-related-post-slider-block').each(function (index) {
                if ($(this).hasClass('slick-initialized')) {
                    $(this).slick('unslick');
                    Slider.initializeBlockSlider($(this));
                } else {
                    Slider.initializeBlockSlider($(this));
                }
            });
        });
    }

    static initializeHomeSlider() {
        //Slider home
        let $slider = $('.sliderList'),
            sliderItem = '.sliderItemPhoto';

        if ($(sliderItem).length > 0) {
            $slider.slick({
                slide: sliderItem,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 1000,
                arrows: false,
                dots: true,
                fade: true,
                cssEase: 'linear',
            });
        }

        // Slider strate destinations
        $('.strateIncontournableSliderInner').slick({
            slide: '.entityItem',
            speed: 1000,
            autoplay: false,
            autoplaySpeed: 5000,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            infinite: false,
            pauseOnHover: true,
            fade: false,
            swipe: false,
            prevArrow: $('.strateIncontournableSliderArrowPrev'),
            nextArrow: $('.strateIncontournableSliderArrowNext'),
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 4,
                        arrows: false,
                        swipe: true,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                        arrows: false,
                        swipe: true,
                    }
                },
                {
                    breakpoint: 650,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        dots: true,
                        swipe: true,
                    }
                },
            ]
        });


        $('.strateEvenementsInnerList').on('init', function (event, slick, direction) {
            Utils.initializeLazy();
        });

        // Slider Temps forts
        $('.strateEvenementsInnerList').slick({
            speed: 1000,
            autoplay: false,
            autoplaySpeed: 5000,
            arrows: true,
            dots: false,
            infinite: true,
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            focusOnSelect: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 739,
                    settings: {
                        arrows: false,
                        dots: false,
                        swipe: true,
                    }
                },
                {
                    breakpoint: 650,
                    settings: {
                        arrows: false,
                        dots: true,
                        swipe: true,
                    }
                },
            ]
        });
    }

    static initializeBlockSlider($element) {

        let isStayTemplate = $('body').hasClass('single-stay');
        let isThingsToDoTemplate = $('body').hasClass('single-things-to-do');

        var slidesToShow = 3;
        var slidesToShowTablet = 2;
        var slidesToShowMobile = 1;

        if (isThingsToDoTemplate || isStayTemplate) {
            slidesToShow = 2;
            slidesToShowTablet = 1
            slidesToShowMobile = 1;
        }

        $element.slick({
            slide: '.wp-block-related-post-inner-item',
            speed: 1000,
            autoplay: false,
            autoplaySpeed: 5000,
            arrows: true,
            dots: false,
            infinite: false,
            pauseOnHover: true,
            slidesToShow: slidesToShow,
            swipe: false,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: slidesToShowTablet,
                    }
                },
                {
                    breakpoint: 739,
                    settings: {
                        slidesToShow: slidesToShowMobile,
                        arrows: false,
                        swipe: true,
                        dots: true,
                    },
                },
            ]
        });

    }

    static initializeBlockSliderGuttemberg() {

        const $element = $('[data-trigger="wpiris-block-slider"]');

        $('.wp-block-slider').on('init', function (event, slick, direction) {
            Utils.initializeLazy();
        });

        $element.slick({
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            variableWidth: true,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 650,
                    settings: {
                        slidesToShow: 2,
                    }
                },
            ]
        });

    }
}
