import '@cdt81/js/tools/storymap';

export default class RoadTrip {
	
	constructor()
	{
		RoadTrip.story_map();
		RoadTrip.show_map();
	}
	
	static story_map()
	{
		const road_trip_list = $( '.js-roadtripList' ),
			  $road_trip_map = $( '.js-roadtripMap' );
		
		if ( road_trip_list.length > 0 ) {
			
			road_trip_list.storymap( {
										 markers: markers,
										 id: $road_trip_map.attr( 'id' )
									 } );
			
		}
	}
	
	static show_map()
	{
		const trigger  = '.js-trigger-map',
			  $element = $( '.js-roadtripMap' );
		
		$( document ).on( 'click', trigger, function ( e ) {
			$element.toggleClass( 'roadtripMap--show' );
		} );
	}
}