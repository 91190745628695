export default class Form {
	
	constructor()
	{
		Form.input();
		Form.select();
		Form.range();
	}
	
	static input()
	{
		let $elements = $( '.wpcf7 [type="checkbox"], .wpcf7 [type="radio"]' );
		
		$( $elements ).each( function ( index, element ) {
			let id         = $( element ).attr( 'name' ) + '-' + index;
			let label_text = $( element ).siblings( '.wpcf7-list-item-label' ).text();
			let label      = '<label for="' + id + '">' + label_text + '</label>';
			
			$( element ).siblings( '.wpcf7-list-item-label' ).remove();
			$( element ).parent().append( label );
			$( element ).attr( 'id', id );
		} );
	}
	
	static select()
	{
		$( 'select' ).parent().not( '.formSelect' ).children( 'select' ).wrap( '<div class="formSelect"></div>' );
	}

	static range() {
		const $searchEngineRange = $('#price-stay');
		const $rangeValue = $('.js-dynamic-value');
		let value = $searchEngineRange.val();
		$rangeValue.text(value);

		$searchEngineRange.on('input change', function () {
			value = $(this).val();
			$rangeValue.text(value);
		})
	}
}