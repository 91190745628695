import L from 'leaflet';
import omnivore from '@mapbox/leaflet-omnivore';

export default class Map {

    static #map;

    constructor()
    {
        Map.map();
        Map.add_kml();
    }
    
    static map()
    {
        Map.map_api( '[data-id="wpiris-map"]' );
    }

    static map_api( $element )
    {
        if ( $( $element ).length > 0 ) {
            let marker_bounds = [],
                markers       = [],
                $markers      = $( $element ).find( '[data-id="wpiris-map-marker"]' );
            Map.#map = L.map( 'map', {
                center: [
                    parseFloat( Theme.latitude ),
                    parseFloat( Theme.longitude )
                ],
                zoom: 11,
                scrollWheelZoom: false
            } );
            L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            } ).addTo( Map.#map );
            $.each( $markers, function ( index, data ) {
                let marker_icon = L.icon( {
                    iconUrl: Theme.themeURL + '/assets/dist/images/markers/marker.svg',
                    iconSize: [
                        30,
                        30
                    ]
                } );
                let marker = L.featureGroup()
                    .addLayer( L.marker( [
                        parseFloat( $( this ).data( 'lat' ) ),
                        parseFloat( $( this ).data( 'lng' ) )
                    ], { icon: marker_icon } ) )
                    .bindPopup( $( this ).html(), {
                        className: 'light',
                        autoPanPadding: L.point( 30, 30 ),
                    } )
                    .addTo( Map.#map );
                markers.push( marker );
                marker_bounds.push( [
                    parseFloat( $( this ).data( 'lat' ) ),
                    parseFloat( $( this ).data( 'lng' ) )
                ] );
            } );
            let group = L.featureGroup( markers );
            Map.#map.fitBounds( group.getBounds(), {padding: [50,100]} );
        }
    }
    
    static render_infobox( data )
    {
        
        let html = '<div class="infoBoxContent">' +
                   '<p class="infoBoxContentTitle">' + data.title + '</p>';
        
        if ( data.street || data.postalcode || data.city ) {
            
            html += '<p class="infoBoxContentExcerpt">' +
                    data.street + '<br>' +
                    data.postalcode + ' ' + data.city + '<br>' +
                    '</p>';
        }
        
        html += '<p class="text-center">' +
                '<a class="infoBoxContentItinerary" href="https://www.google.com/maps/dir/Ma+position/' + parseFloat( data.latitude ) + ',' + parseFloat( data.longitude ) + '" target="_blank"><i class="fas fa-location-arrow"></i>' + Theme.translate.calculate_my_itinerary + '</a>' +
                '</p>' +
                '</div>';
        
        return html;
    };

    static add_kml()
    {
        let element = "[data-id='wpiris-kml-file']";
        if ( $( element ).length > 0 ) {
            let kml = $( element ).attr( 'data-kml' );
            let customLayer = L.geoJson( null, {
                style: function ( feature ) {
                    return { color: '#000' };
                }
            } );
            let runLayer    = omnivore.kml( kml, null, customLayer ).addTo( Map.#map );
            runLayer.on( 'ready', function () {
                Map.#map.fitBounds( runLayer.getBounds() );
            } );
        }
    }
}
