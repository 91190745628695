import Isotope from 'isotope-layout/dist/isotope.pkgd.min';
import imagesLoaded from 'imagesloaded/imagesloaded.pkgd.min';

export default class Gallery {
    
    constructor()
    {
        this.initializeIsotope();
    }

    initializeIsotope()
    {
        let grid     = document.querySelector( '[isotope-grid]' ),
            item     = '[isotope-item]',
            filters  = '[isotope-filters]',
            $filters = $( filters ),
            active   = 'current',
            gutterSize = 40,
            gutterSizeTablet = 30,
            gutterSizeMobile = 20;

        if ( window.matchMedia( "(max-width: 1000px)" ).matches ) {
            gutterSize   = gutterSizeTablet;
        }

        if (window.matchMedia("(max-width: 650px)").matches) {
            gutterSize = gutterSizeMobile;
        }

        if ( document.body.contains( grid ) ) {

            $(window).load(function () {

                let iso = new Isotope( grid, {
                    masonry: {
                        itemSelector: item,
                        gutter: gutterSize,
                    }
                } );

                // Layout Isotope after each image loads
                let imgLoad = new imagesLoaded( iso );
                imgLoad.on( 'progress', function () {
                    new Isotope( grid, 'layout' );
                } );

                if ( $filters.length > 0 ) {
                    // Filter items on button click
                    $filters.on( 'click', '.filterItem', function () {
                        let filterValue = $( this ).attr( 'data-filter' );
                        iso.arrange( { filter: filterValue } );
                    } );

                    // Change class on click
                    $filters.each( function ( i, buttonGroup ) {
                        let $buttonGroup = $( buttonGroup );
                        $buttonGroup.on( 'click', '.filterItem', function () {
                            $buttonGroup.find( '.' + active ).removeClass( active );
                            $( this ).addClass( active );
                        } );
                    } );
                }

            });
        }

    }
}