import Modernizr from 'modernizr';
import Utils from '@cdt81/js/services/utils';
import bodymovin from 'lottie-web/build/player/lottie.min.js';

export default class TourismAffinity {

	constructor() {

		TourismAffinity.tools_menus();
		TourismAffinity.wording_tourism_affinity();
		TourismAffinity.tools_tourism_affinity();
		TourismAffinity.modal_tourism_affinity();
		// TourismAffinity.inputAxeptio();

		$( document ).bind( 'click', '.wpetTourismAffinityToggleSwitch', function ( e ) {
			TourismAffinity.wording_tourism_affinity();
		})

		$( document ).on( 'wpet_tourism_affinity_reload_block', function ( e ) {
			TourismAffinity.wording_tourism_affinity();
			Utils.tooltip();
			Utils.initializeLazy();
		} );

		$( document ).on( 'frmPageChanged', function(e)  {
			TourismAffinity.renderAxeptio();
		});

	}

	static tools_menus()
	{
		const $trigger = $('[data-trigger="wpiris-tourism-affinity-popover"]'), // The menu itself
			$element = $trigger.find('> a'); // The first item in the menu that used as trigger


		// If click outside menu, force close it
		$('html').click(function(e) {
			// console.log(e);
			$trigger.removeClass('--active');
			// console.log('ouii');
		});

		// If we are not on a touch device
		if ( Modernizr.touchevents ) {
			$element.click(function (e) {
				e.preventDefault();
				$trigger.toggleClass('--active');
			});

			// Stop event propagation to not close menu if we click in it
			$trigger.click(function (e) {
				// console.log(e);
				e.stopPropagation();
			});

		} else { // Standard device using hover system
			$trigger.mouseenter(function () {
				$trigger.addClass('--active');
			});

			//$trigger.mouseleave(function () {
			//	$trigger.removeClass('--active');
			//});
		}
	}

	static wording_tourism_affinity()
	{
		$( '[data-trigger="wpiris-label-tourism-affinity"]' ).each( function () {

			const $trigger = $( this );

			if ( $trigger.hasClass( 'active' ) ) {
				$trigger.parents( '.wpetTourismAffinityToggle' ).find( '.wpetTourismAffinityToggleLabel' ).text( 'Désactiver Tourism Affinity' );
			} else {
				$trigger.parents( '.wpetTourismAffinityToggle' ).find( '.wpetTourismAffinityToggleLabel' ).text( 'Activer Tourism Affinity' );
			}
		} );
	}

	static tools_tourism_affinity()
	{
		const file = Theme.themeURL +'/assets/dist/images/tourism-affinity/icon-tourism-affinity.json',
			element = document.getElementById('svg-container'),
			element_list = document.getElementById('svg-container-list');

		if ( $(element).length ) {
			bodymovin.loadAnimation({
				wrapper: element,
				animType: 'svg',
				loop: true,
				path: file
			});
		}

		if ( $(element_list).length ) {
			bodymovin.loadAnimation({
				wrapper: element_list,
				animType: 'svg',
				loop: true,
				path: file
			});
		}
	}

	static modal_tourism_affinity()
	{

		const trigger  = '[data-trigger="wpiris-tourism-affinity"]',
			$element = $( '[data-element="wpiris-tourism-affinity"]' );

		$( document ).on( 'click', trigger, function ( e ) {
			e.preventDefault();
			$element.toggleClass('active');
		} );
	}

	static renderAxeptio()
	{

		void 0 === window._axcb && (window._axcb = []);
		window._axcb.push( function ( axeptio ) {

			var clientNode = document.querySelector('.js-insert-axeptio-tourism-affinity .formGroupWrap');

			if ( typeof(clientNode) != 'undefined' && clientNode != null ) {

				var elementToHide = document.querySelector('.js-insert-axeptio-tourism-affinity .formGroupWrap .frm_checkbox');
				var clientCheckbox = document.querySelector('.js-insert-axeptio-tourism-affinity .formGroupWrap input[type="checkbox"][id*="axeptio_consent"]');

				// Ne pas toucher
				// Insertion du widget
				var axeptioSpan = document.createElement( 'div' );
				axeptioSpan.id = "axeptio_mount";
				axeptioSpan.className = "axeptio_mount";
				clientNode.appendChild(axeptioSpan);

				const config = axeptio.getWidgetConfig({
					service: "processings",
					identifier: "641c80be18925a9a43a19ccd"
				});

				axeptio.renderWidget({
					config: Object.assign({}, config, {isRequired: true}),
					service: 'processings',
					node: document.getElementById('axeptio_mount')
				});

				elementToHide.style.display = "none";

				var inputValue = document.getElementById("axeptio_consent_checkbox").value;
				if ( inputValue == '1' ) {
					$('.js-insert-axeptio-tourism-affinity .formGroupWrap input[type="checkbox"][id*="axeptio_consent"]').prop("checked", true);
				} else {
					$('.js-insert-axeptio-tourism-affinity .formGroupWrap input[type="checkbox"][id*="axeptio_consent"]').prop("checked", false);
				}

			}
		});
	}
}
