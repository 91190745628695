import 'slick-carousel/slick/slick.min';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import Modernizr from 'modernizr';
import LazyLoad from "vanilla-lazyload";
import bodymovin from 'lottie-web/build/player/lottie.min.js';

export default class Utils {
    constructor() {
        Utils.mapHome();
        Utils.click();
        Utils.tooltip();
        Utils.fancybox();
        Utils.searchbox();
        Utils.smooth_scroll();
        Utils.initializeSmoothScroll();
        Utils.initializeLazy();
        Utils.autoNaving();
        Utils.menu();
        Utils.initializeInstagram();
        Utils.blockSwipe();
    }

    static mapHome() {
        $(".territoryList .territoryContent").hide();

        $("#tarnMap #vignoble").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #vignoble').fadeIn(0);
            },
            function () {
                $('.territoryList #vignoble').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #decouverte").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #decouverte').fadeIn(0);
            },
            function () {
                $('.territoryList #decouverte').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #monts").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #monts').fadeIn(0);
            },
            function () {
                $('.territoryList #monts').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #jacquemart").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #jacquemart').fadeIn(0);
            },
            function () {
                $('.territoryList #jacquemart').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #cascades").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #cascades').fadeIn(0);
            },
            function () {
                $('.territoryList #cascades').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #rochers").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #rochers').fadeIn(0);
            },
            function () {
                $('.territoryList #rochers').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #viaduc").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #viaduc').fadeIn(0);
            },
            function () {
                $('.territoryList #viaduc').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #cites").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #cites').fadeIn(0);
            },
            function () {
                $('.territoryList #cites').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #castre").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #castre').fadeIn(0);
            },
            function () {
                $('.territoryList #castre').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #albi").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #albi').fadeIn(0);
            },
            function () {
                $('.territoryList #albi').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #ambialet").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #ambialet').fadeIn(0);
            },
            function () {
                $('.territoryList #ambialet').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #passerelle").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #passerelle').fadeIn(0);
            },
            function () {
                $('.territoryList #passerelle').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #canal").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #canal').fadeIn(0);
            },
            function () {
                $('.territoryList #canal').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #soreze").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #soreze').fadeIn(0);
            },
            function () {
                $('.territoryList #soreze').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

        $("#tarnMap #souterrain").hover(
            function () {
                $('.strateSituationContent').fadeOut(0);
                $('.strateSituationContentThumbnail').fadeOut(0);
                $('.strateSituationContentTitle').fadeOut(0);
                $('.strateSituationContentExcerpt').fadeOut(0);
                $('.territoryList #souterrain').fadeIn(0);
            },
            function () {
                $('.territoryList #souterrain').fadeOut(0);
                $('.strateSituationContent').fadeIn(0);
                $('.strateSituationContentThumbnail').fadeIn(0);
                $('.strateSituationContentTitle').fadeIn(0);
                $('.strateSituationContentExcerpt').fadeIn(0);
            }
        );

    }

    static click() {

        const trigger = '[data-trigger="wpiris-click"]';

        if (!Modernizr.touchevents) {
            let ctrl = 0;
            $(window).on('keydown', function (e) {
                if (e.which == 17 || e.which == 91) {
                    ctrl = true
                }
            })
            $(window).on('keyup', function () {
                ctrl = false
            })

            $(document).on('mouseup', trigger, function (e) {

                if (e.which == 1 || e.which == 2) {
                    let blank = false
                    let url = $(this).find("a").attr("href")
                    if (e.which == 2) {
                        blank = true
                    } // Clic molette
                    if ($(this).find("a").attr("target") == "_blank") {
                        blank = true
                    }
                    if (blank || ctrl) {
                        window.open(url, '_blank')
                    } else {
                        window.location = url
                    }
                    return false
                }
                return false

            })
        } else {
            $(document).on('click', trigger, function (e) {

                let blank = false
                let url = $(this).find("a").attr("href")
                if ($(this).find("a").attr("target") == "_blank") {
                    blank = true
                }
                if (blank) {
                    window.open(url, '_blank')
                } else {
                    window.location = url
                }
                return false

            })
        }

        $('.is-style-download').attr('data-trigger', 'wpiris-click');
    }

    static tooltip() {
        if (!Modernizr.touchevents) {
            $('[data-toggle="tooltip"], [rel="tooltip"]').tooltip({
                trigger: "hover",
                container: "body",
                template:'<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
            });
        }
    }

    static fancybox() {

        $('.hentry a:has(>img):not([target="_blank"]):not("[nofancybox]")').attr('data-fancybox', 'fancybox')

        $('[data-fancybox="fancybox"]').fancybox({
            buttons: [
                "zoom",
                "slideShow",
                "close"
            ],
            lang: "fr",
            i18n: {
                fr: {
                    CLOSE: Theme.translate.fancyboxClose,
                    NEXT: Theme.translate.fancyboxNext,
                    PREV: Theme.translate.fancyboxPrev,
                    ERROR: Theme.translate.fancyboxError,
                    PLAY_START: Theme.translate.fancyboxStart,
                    PLAY_STOP: Theme.translate.fancyboxStop,
                    FULL_SCREEN: Theme.translate.fancyboxFullScreen,
                    THUMBS: Theme.translate.fancyboxThumbs,
                    DOWNLOAD: Theme.translate.fancyboxDownload,
                    SHARE: Theme.translate.fancyboxShare,
                    ZOOM: Theme.translate.fancyboxZoom
                },
            },
            caption: function (instance, item) {
                let caption = $(this).closest('figure').find('figcaption').html()

                if (!caption) {
                    caption = $(this).find('img').attr('title')
                }

                if (!caption) {
                    caption = $(this).find('img').attr('alt')
                }

                return caption
            }
        });
        $('[data-fancybox="fancybox-iframe"]').fancybox({
            type: "iframe",
            lang: "fr"
        });
    }

    static searchbox() {
        const trigger = '[data-trigger="wpiris-search-layout"]',
            close = '[data-trigger="wpiris-search-layout-close"]',
            $element = $('[data-id="wpiris-search-layout"]');

        $(document).on('click', trigger, function (e) {
            e.preventDefault();
            $element.fadeIn().find('input').focus();
        })

        $(document).on('click', close, function (e) {
            e.preventDefault();
            $element.fadeOut();
        })
    }

    static smooth_scroll() {
        const trigger = '[data-trigger="wpiris-scroll"]';

        $(document).on('click', trigger, function (e) {
            e.preventDefault();

            const target = $(this).attr("href") != '' ? $(this).attr("href") : $(this).attr("data-href");
            const duration = 1000;

            $('html, body').animate({
                scrollTop: $(target).offset().top
            }, duration);

        })
    }

    static initializeSmoothScroll() {
        $('.js-scroll').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                let target = $(this.hash)
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 750)
                    return false
                }
            }
        })
    }

    static initializeLazy() {
        let lazyLoadInstance = new LazyLoad({
            elements_selector: "[data-src], [data-bg]"
        });
    }

    static autoNaving() {
        let last_scroll_top = 0;
        $(window).on('load scroll', function () {

            let scroll_top = $(this).scrollTop();
            let $header = $('.navbar');

            if (scroll_top >= last_scroll_top && scroll_top > $header.outerHeight()) {
                // Down
                $('body').addClass('header-is-hidden');
            } else {
                // Up
                $('body').removeClass('header-is-hidden');
            }

            last_scroll_top = scroll_top;

            // Site header color
            if ($('.header-is-transparent').length) {

                if (scroll_top > 100) {
                    $header.removeClass('navbar--transparent');
                } else {
                    $header.addClass('navbar--transparent');
                }
            }
        });
    }

    static menu() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]',
            $element = $('[data-id="wpiris-menu"]'),
            elementItem = '[data-id="wpiris-menu-item"]',
            triggerMore = '[data-trigger="wpiris-menu-more"]',
            triggerBack = '[data-trigger="wpiris-menu-back"]';

        $(document).on('click', trigger, function (e) {
            e.preventDefault();
            $element.toggleClass('open');
            $(trigger).toggleClass('open');
            $('body').toggleClass('menuOpen');
            $(elementItem).removeClass('submenuActive');
        });

        $(document).on('click', triggerMore, function (e) {
            $(elementItem).removeClass('submenuActive');
            $(this).parents(elementItem).addClass('submenuActive');
        });

        $(document).on('click', triggerBack, function (e) {
            $(this).parents(elementItem).removeClass('submenuActive');
        });
    }

    static initializeInstagram() {

        let $wrapper = $('[data-instagram-wall]');

        if ($wrapper.length > 0) {
            self = this;
            $.ajax({
                url: Theme.ajaxURL,
                data: {
                    'action': 'social_wall',
                },
                type: 'POST',
                success: function (socialData) {
                    $wrapper.append(socialData);
                    self.initializeLazy();
                }
            });
        }
    }

    static wording_tourism_affinity()
    {
        $( '.wpetTourismAffinitySwitch' ).each( function () {

            const $trigger = $( this );

            if ( $trigger.find( '.wpetTourismAffinitySwitchSlider' ).hasClass( 'active' ) ) {
                $trigger.parents( '.toolsItem--taSubmenuItem--switch' ).find( '[data-trigger="wpiris-label-tourism-affinity"]' ).text( 'Désactiver Tourism Affinity' );
                $trigger.attr( 'data-original-title', 'Désactiver Tourism Affinity' );

            } else {
                $trigger.parents( '.toolsItem--taSubmenuItem--switch' ).find( '[data-trigger="wpiris-label-tourism-affinity"]' ).text( 'Activer Tourism Affinity' );
                $trigger.attr( 'data-original-title', 'Activer Tourism Affinity' );
            }
        } );
    }

    static tools_tourism_affinity()
    {
        var file = Theme.themeURL +'/assets/dist/images/animation/tools-tourism-affinity.json';
        var svgContainer = document.getElementById('svgContainer');
        var animItem = bodymovin.loadAnimation({
            wrapper: svgContainer,
            animType: 'svg',
            loop: true,
            path: file
        });
    }

    static blockSwipe() {
        $(".thingsInfosPratListItem--0").addClass("active");
        $(".thingsInfosPratContent--0").addClass("active");
        $(".thingsInfosPratContent--1").hide();
        $(".thingsInfosPratContent--2").hide();

        $(".thingsInfosPratListItem--0").click(function(event){
            event.preventDefault();

            $(".thingsInfosPratContent--0").show();
            $(".thingsInfosPratContent--1").hide();
            $(".thingsInfosPratContent--2").hide();

            $(".thingsInfosPratListItem--0").addClass("active");
            $(".thingsInfosPratListItem--1").removeClass("active");
            $(".thingsInfosPratListItem--2").removeClass("active");
        });

        $(".thingsInfosPratListItem--1").click(function(event){
            event.preventDefault();

            $(".thingsInfosPratContent--1").show();
            $(".thingsInfosPratContent--0").hide();
            $(".thingsInfosPratContent--2").hide();

            $(".thingsInfosPratListItem--1").addClass("active");
            $(".thingsInfosPratListItem--0").removeClass("active");
            $(".thingsInfosPratListItem--2").removeClass("active");
        });

        $(".thingsInfosPratListItem--2").click(function(event){
            event.preventDefault();

            $(".thingsInfosPratContent--2").show();
            $(".thingsInfosPratContent--0").hide();
            $(".thingsInfosPratContent--1").hide();

            $(".thingsInfosPratListItem--2").addClass("active");
            $(".thingsInfosPratListItem--0").removeClass("active");
            $(".thingsInfosPratListItem--1").removeClass("active");
        });
    }

}
